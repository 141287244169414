<script setup lang="ts">
import type { KippieLocation } from "@shopware-pwa/types";
import useVuelidate from "@vuelidate/core";
import { required, email } from "~/lib/validator/validators";
import { getValidatorFieldProps } from "~/helpers/formHelpers";

const props = defineProps<{
	pickupLocation: KippieLocation;
	defaultEmail?: string;
	closeDrawer: () => void;
}>();

const isLoading = ref(false);
const formValues = ref({
	email: props.defaultEmail || "",
	message: ""
});

const snackbar = useSnackbar();
const validator = useVuelidate(
	{
		email: { required, email },
		message: { required }
	},
	formValues.value
);

const onSubmit = async (e: Event) => {
	e.preventDefault();
	isLoading.value = true;

	if (!(await validator.value.$validate())) {
		isLoading.value = false;
		return;
	}

	try {
		await $fetch("/api/messaging/mail", {
			method: "POST",
			body: {
				recipient: props.pickupLocation.email,
				replyTo: formValues.value.email,
				message: formValues.value.message
			}
		});

		snackbar.open({
			content: `Jouw bericht is verstuurd naar ${props.pickupLocation.name}, ze nemen contact met je op via E-mail.`,
			icon: "check",
			iconColor: "green"
		});
	} catch (error) {
		snackbar.open({
			content: `Er is iets misgegaan bij het versturen van je bericht. Neem contact op met ${props.pickupLocation.email}`,
			icon: "close",
			iconColor: "red"
		});
	} finally {
		isLoading.value = false;
		props.closeDrawer();
	}
};
</script>

<template>
	<div class="flex flex-col gap-6">
		<form class="flex flex-col gap-4" @submit="onSubmit">
			<KippieInput
				v-model="formValues.email"
				label="E-mailadres"
				type="email"
				required
				placeholder="Vul hier je e-mailadres in"
				name="email"
				v-bind="getValidatorFieldProps(validator, 'email')"
			/>

			<KippieInput
				type="textarea"
				v-model="formValues.message"
				placeholder="Vul hier je vraag of opmerking in"
				:rows="3"
				v-bind="getValidatorFieldProps(validator, 'message')"
			/>
			<KippieButton block size="small" type="submit" :loading="isLoading"> Versturen </KippieButton>
		</form>
	</div>
</template>
